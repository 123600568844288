import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import { Tab, TabPanel, Tabs } from "../../../components/mui/Tabs"
import Divider from "../../../components/dividers/Divider"
import DataTable, { CommonColumnUserRole } from "../../../components/mui/DataTable"
import MetaData from "../../../components/MetaData"
import { getOrganisationGroupItemFunc } from "../../../components/httpRequests/OrganisationGroupRequests"
import ButtonMenu from "../../../components/mui/ButtonMenu"
import useUser from "../../../hooks/useUser"
import { axiosErrorMessage } from "../../../components/axios"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import UserRolesModal, { userRoleObject } from "../users/UserRolesModal"
import NavBack from "../../../components/nav/NavBack"
import ProfilePhoto from "../../../components/ProfilePhoto"

/**
 * The basic link
 */
const BasicOrganisationLink = (params) => <Link to={`/organisation/${params.id}`}>{params.value}</Link>

/**
 * Data Table fields (Orgs)
 */
const orgColumns = [
    {
        field: "orgUuid",
        headerName: "Id",
        flex: 1,
        renderCell: BasicOrganisationLink,
    },
    {
        field: "orgCode",
        headerName: "Code",
        flex: 0.5,
        renderCell: BasicOrganisationLink,
    },
    {
        field: "orgName",
        headerName: "Organisation Name",
        flex: 1,
        minWidth: 100,
        hideable: false,
        renderCell: (params) => (
            <Link to={`/organisation/${params.id}`}>
                {params.row.disabledAt && (
                    <span className='pill-danger' style={{ marginRight: "4px" }}>
                        DISABLED
                    </span>
                )}
                {params.value}{" "}
            </Link>
        ),
    },
    {
        field: "description",
        headerName: "Description",
        flex: 1,
        minWidth: 100,
        renderCell: BasicOrganisationLink,
    },
    {
        field: "disabledAt",
        headerName: "Disabled",
        flex: 0.5,
        type: "boolean",
        minWidth: 50,
    },
    {
        field: "userAmount",
        headerName: "Amount of Users in Organisation",
        description: "The number of users in this organisation",
        type: "number",
        flex: 0.5,
        minWidth: 50,
    },
]

/**
 * Single group page
 */
const Group = () => {
    const navigate = useNavigate()
    const { addSnackbarMessage } = useAlertContext()
    const { groupUuid } = useParams()
    const { hasAuthRole_Admin } = useUser()

    const [userRole, setUserRole] = useState(null)
    const [groupInfo, setGroupInfo] = useState(null)
    const [tabIndex, setTabIndex] = useState(0)

    const isAdmin = hasAuthRole_Admin()

    /**
     * Get the group data
     */
    const getInitialData = async (abortController) => {
        try {
            const res = await getOrganisationGroupItemFunc(groupUuid, abortController)
            setGroupInfo(res.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        }
    }

    /**
     * Close the user permission modal
     */
    const doCloseRolesModal = (updated = false) => {
        setUserRole(null)
        if (updated) getInitialData()
    }

    /**
     * Opens the roles model
     */
    const doOpenRolesModal = (action, userUuid, roleId = undefined) => {
        setUserRole({ ...userRoleObject, userUuid, itemUuid: groupUuid, roleId, action })
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()

        if (groupUuid) getInitialData()
        else navigate("/admin/groups")

        // Clean up function
        return () => abortController.abort()
    }, [groupUuid])

    /**
     * Data Table fields (Users)
     */
    const userColumns = [
        {
            field: "uuid",
            headerName: "Id",
            flex: 1,
            renderCell: (params) => <Link to={`/admin/user/${params.id}`}>{params.value}</Link>,
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => (
                <Link to={`/admin/user/${params.id}`} className='profile-cell'>
                    <ProfilePhoto userUuid={params.id} userName={params.value} size='small' />
                    {params.value}
                </Link>
            ),
        },
        {
            field: "role",
            headerName: "Group Role",
            flex: 1,
            renderCell: CommonColumnUserRole,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            renderCell: (params) => <Link to={`/admin/user/${params.id}`}>{params.value}</Link>,
        },
        {
            field: "phone",
            headerName: "Phone",
            flex: 1,
            renderCell: (params) => <Link to={`/admin/user/${params.id}`}>{params.value}</Link>,
        },
        {
            field: "__actions",
            headerName: "...",
            flex: 1,
            hideable: false,
            headerAlign: "right",
            align: "right",
            maxWidth: 50,
            renderCell: (params) =>
                isAdmin ? (
                    <>
                        <ButtonMenu
                            id={params.id}
                            menuItems={[
                                {
                                    label: "Edit User Permissions",
                                    icon: EditIcon,
                                    func: () => doOpenRolesModal("edit", params.row.uuid, params.row.role?.value),
                                },
                                {
                                    label: "Delete User",
                                    icon: DeleteForeverIcon,
                                    func: () => doOpenRolesModal("delete", params.row.uuid),
                                },
                            ]}
                        />
                    </>
                ) : null,
        },
    ]

    return (
        <>
            {userRole && <UserRolesModal isAdmin={isAdmin} isOrganisation={false} onCloseModal={doCloseRolesModal} userRole={userRole} />}
            <div className='page-wrapper'>
                <MetaData title={`Group`} />
                <div className='padding-16 divider-line-black-bottom'>
                    <div className='flex-row'>
                        <NavBack to={`/admin/groups`} />
                        <Link to={`/admin/groups/${groupUuid}/edit`} style={{ textDecoration: "none" }} className='button-outlined'>
                            Edit Group
                        </Link>
                    </div>
                </div>
                <div className='padding-16'>
                    {groupInfo && (
                        <div className='flex-column'>
                            <MetaData title={`${groupInfo.name} Group`} />
                            <h2 className='heading'>{groupInfo.name} Group</h2>
                            <p className='text-faded'>{groupInfo.description && groupInfo.description}</p>
                        </div>
                    )}

                    <Divider padding={16} />

                    <Tabs
                        value={tabIndex}
                        onChange={(event, newValue) => {
                            setTabIndex(newValue)
                        }}
                        className='margin-vertical-8'
                    >
                        <Tab label='Group Organisations' id='simple-tab-0' />
                        <Tab label='Group Users' id='simple-tab-1' />
                    </Tabs>

                    <TabPanel value={tabIndex} index={0} className='width-100'>
                        <DataTable
                            tableId={"group-table"}
                            rows={groupInfo?.organisations}
                            columns={orgColumns}
                            getRowId={(u) => u.orgUuid}
                            defaultColumnVisibility={{
                                orgUuid: false,
                                description: false,
                            }}
                            checkboxSelection={false}
                            hideFooter={false}
                        />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1} className='width-100'>
                        <DataTable
                            tableId={"group-user-table"}
                            rows={groupInfo?.users}
                            columns={userColumns}
                            getRowId={(u) => u.uuid}
                            defaultColumnVisibility={{
                                uuid: false,
                            }}
                            checkboxSelection={false}
                            hideFooter={false}
                        />
                        {isAdmin ? (
                            <button className='button-contained' onClick={() => doOpenRolesModal("create", undefined)}>
                                Add User To Group
                            </button>
                        ) : null}
                    </TabPanel>
                </div>
            </div>
        </>
    )
}

export default Group
