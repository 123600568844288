import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid"
import { Box } from "@mui/material"
import { Link, useSearchParams } from "react-router-dom"

import { useLocalStorageState } from "../../hooks/useStorageState"
import Loading from "../Loading"
import Tooltip from "./Tooltip"
import GoTopButton from "../GoTopButton"
import { useRef } from "react"

/*
Supported column types: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions'

Cell formating fields: ["id","field","row","rowNode","colDef","cellMode","hasFocus","tabIndex","value","formattedValue","isEditable","api"]
*/

/**
 * Wrapper around the MUI DataGrid
 * tableId = local storage name
 * getRowId => (u) => u.uuid
 * hideFooter = undefined | boolean
 * className = undefined | string
 * checkboxSelection = undefined | boolean
 * pageSize = 50
 * defaultColumnVisibility = {uuid:false}
 * onRowsSelectionChange = (keys) => { keys is a array of ids }
 */
function DataTable({
    tableId,
    rows,
    getRowId,
    columns,
    className = undefined,
    defaultColumnVisibility = null,
    hideFooter = undefined,
    checkboxSelection = undefined,
    pageSize = 100,
    onRowsSelectionChange = undefined,
    hideToolbar = false,
}) {
    const [searchParams] = useSearchParams()
    const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorageState(tableId, defaultColumnVisibility)

    const doSetColumnVisibilityModel = (newModel) => {
        setColumnVisibilityModel(newModel)
    }

    const doRowSelectionModelChange = (selectedRows) => {
        if (onRowsSelectionChange) onRowsSelectionChange(selectedRows)
    }

    const id = searchParams?.get("id")

    /**
     * Function to get the row class
     */
    const doGetRowClassName = (params) => {
        // below us double = not tripple so it can match ints to strings ("17" == 17)
        return `data-table-row ${params.id == id ? "data-table-highlight" : ""}`
    }

    /**
     * Pinned rows is only available in MUI pro
     * Gets the row for the padded id
     *
    const getRowFromId = (id) => {
        if (!Array.isArray(rows)) return []

        return rows.filter((row) => {
            return getRowId(row) == id
        })
    }
    const pinnedRows = {
        top: getRowFromId(id),
        // bottom: [getRow()],
    }
    */

    if (!rows) return <Loading />

    return (
        <>
            <DataGrid
                rows={rows}
                //pinnedRows={pinnedRows}
                columns={columns}
                getRowId={getRowId}
                getRowClassName={doGetRowClassName}
                getCellClassName={() => "data-table-cell"}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: pageSize,
                        },
                    },
                }}
                pageSizeOptions={[pageSize]}
                autoHeight
                hideFooter={hideFooter}
                density='compact' // comfortable
                className={`data-table margin-bottom-64 ${tableId} ${className ? className : ""}`}
                columnVisibilityModel={columnVisibilityModel == null ? undefined : columnVisibilityModel}
                onColumnVisibilityModelChange={doSetColumnVisibilityModel}
                checkboxSelection={checkboxSelection}
                checkboxSelectionVisibleOnly={true}
                disableRowSelectionOnClick={checkboxSelection}
                onRowSelectionModelChange={doRowSelectionModelChange}
                slots={{
                    toolbar: hideToolbar ? undefined : DataTableToolbar,
                }}
            />
        </>
    )
}

/**
 * The toolbar at the top of the grid table
 */
const DataTableToolbar = () => {
    const refScrollUp = useRef()
    return (
        <>
            <GridToolbarContainer ref={refScrollUp}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarExport />
            </GridToolbarContainer>
            <GoTopButton refScrollUp={refScrollUp} />
        </>
    )
}

/**
 * Returns a link to the users page and the users role
 * @param {*} params
 */
export const CommonColumnUserRole = (params) => (
    <Tooltip text='The users global role takes priority over the group or organization roles'>
        <Link to={`/admin/user/${params.id}`}>{params.value?.label ?? "unknown"}</Link>
    </Tooltip>
)

export default DataTable
