import { useEffect, useState } from "react"

/**
 * Check if the passed ref is visible or not
 * @param {*} observableRef ref to observe
 * @param {boolean} defaultValue the default state value
 * @returns
 */
export function useIsVisible(observableRef, defaultValue = false) {
    const [isIntersecting, setIntersecting] = useState(defaultValue)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry.isIntersecting)
        })

        observer.observe(observableRef.current)
        return () => {
            observer.disconnect()
        }
    }, [observableRef])

    return isIntersecting
}
