import React from "react"
import { IconButton as MuiIconButton } from "@mui/material"

import Tooltip from "./Tooltip"

/**
 * Icon buttons are commonly found in app bars and toolbars.
 * Icons are also appropriate for toggle buttons that allow a single choice to be selected or deselected, such as adding or removing a star to an item.
 * - color : 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
 * - size: 'small' | 'medium' | 'large'
 */
function IconButton({ ref = undefined, hintText = undefined, onClick = undefined, sx = undefined, size = "medium", color = "default", children }) {
    return (
        <Tooltip text={hintText}>
            <MuiIconButton ref={ref} className={`icon-btn icon-btn-${size}`} onClick={onClick} size={size} color={color} sx={sx}>
                {children}
            </MuiIconButton>
        </Tooltip>
    )
}

export default IconButton
