import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Select from "react-select"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"

import "../../styling/dashboard.css"

import { axiosErrorMessage } from "../../../components/axios"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import useUser from "../../../hooks/useUser"
import Loading from "../../../components/Loading"
import NewGroupPopup from "./NewGroupPopup"
import MetaData from "../../../components/MetaData"
import MarkdownTextArea from "../../../components/mui/MarkdownTextArea"
import { inputMaxLength } from "../../../Constants"
import { getOrganisationGroupValuePairsListFunc } from "../../../components/httpRequests/OrganisationGroupRequests"
import { selectFilter, selectOptionsToUuidValuesArray, selectTheme, uuidValuePairsToSelectOptions } from "../../../common/selectHelpers"
import Divider from "../../../components/dividers/Divider"
import { Header2, Header3 } from "../../../components/headers/Headers"
import RequiredFieldHint from "../../../components/forms/RequiredFieldHint"
import { getOrganisationFunc, patchOrganisationFunc, postOrganisationFunc } from "../../../components/httpRequests/OrganisationRequests"
import Switch from "../../../components/mui/Switch"

/**
 * Create or edit an organisation
 */
const NewOrganisation = () => {
    const navigate = useNavigate()
    const { orgUuid } = useParams()
    const { addSnackbarMessage } = useAlertContext()
    const { setCurrentOrgUuid, hasAuthRole_Admin, hasAuthRole_TechnicianOrAbove } = useUser()

    // If this is a new item or an edit
    const isNewItem = !orgUuid || orgUuid == null || orgUuid == undefined
    const isAdmin = hasAuthRole_Admin()
    const isTechnicianOrAbove = hasAuthRole_TechnicianOrAbove()

    const [orgData, setOrgData] = useState(null)
    const [groupList, setGroupList] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isNewGroupOpen, setIsNewGroupOpen] = useState(false)

    const returnToOrgList = () => {
        navigate(`/organisations`)
    }

    /**
     * Text input changed
     */
    const onInputChange = (e) => {
        const name = e.target.id
        setOrgData((currentValue) => {
            return { ...currentValue, [name]: e.target.value }
        })
    }

    /**
     * React-Select dropdown changed
     */
    const onSelectChange = (newValue, actionMeta) => {
        const name = actionMeta?.name
        if (!name) {
            console.error("name is not defined")
            return
        }

        // check this group can be removed / added
        if (name === "groups") {
            switch (actionMeta.action) {
                case "remove-value":
                case "pop-value":
                    if (actionMeta.removedValue.isFixed) return
                    break
                case "clear":
                    newValue = actionMeta.removedValues?.filter((v) => v.isFixed)
                    break
            }
        }

        // Update the selected filter
        setOrgData((currentFilter) => {
            return { ...currentFilter, [name]: selectOptionsToUuidValuesArray(newValue), isCustomFilter: true }
        })
    }

    /**
     * Get the org data
     */
    const findQuery = async (abortController) => {
        if ((!isNewItem || !isTechnicianOrAbove) && !orgUuid) {
            returnToOrgList()
            return
        }

        setIsLoading(true)
        try {
            const groupsRequest = isTechnicianOrAbove ? getOrganisationGroupValuePairsListFunc(abortController) : Promise.resolve()
            const organisationRequest = isNewItem ? Promise.resolve() : getOrganisationFunc(orgUuid, abortController)
            const [orgData, orgGroups] = await Promise.all([organisationRequest, groupsRequest])

            setOrgData(orgData?.data)
            setCurrentOrgUuid(orgData?.data?.uuid ?? null)
            setGroupList(orgGroups?.data ?? null)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
                returnToOrgList()
            }
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Validate the data is OK
     */
    const isDataValid = () => {
        if (!orgData) return false
        if (!orgData?.code || !orgData?.name || !orgData?.address || !orgData?.phone1) return false
        if (!isNewItem && !orgData?.uuid) return false
        return true
    }

    /**
     * Save the changes
     */
    const doSubmit = async (e) => {
        e.preventDefault()
        if (isSubmitting || isLoading || !isDataValid()) {
            return
        }

        setIsSubmitting(true)
        try {
            if (isNewItem) {
                const res = await postOrganisationFunc(orgData)
                if (res.status === 200) {
                    addSnackbarMessage("Organisation Created Successfully.", AlertTypes.Success)
                    navigate(`/organisation/${res.data?.value}`)
                } else {
                    addSnackbarMessage("Organisation Not Created.", AlertTypes.Error)
                }
            } else {
                const res = await patchOrganisationFunc(orgData)
                if (res.status === 200) {
                    addSnackbarMessage("Organisation Updated Successfully.", AlertTypes.Success)
                    navigate(`/organisation/${res.data?.value}`)
                } else {
                    addSnackbarMessage("Organisation Not Updated.", AlertTypes.Error)
                }
            }
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        } finally {
            setIsSubmitting(false)
        }
    }

    /**
     * Called when the popup should close
     */
    const doClosePopup = (newItem) => {
        setIsNewGroupOpen(false)
        if (newItem) {
            setGroupList((currentValue) => [...currentValue, newItem])
            setOrgData((currentValue) => {
                const newGroupArr = selectOptionsToUuidValuesArray([newItem])
                const groups = currentValue ? [...currentValue.groups, ...newGroupArr] : newGroupArr
                return { ...currentValue, groups }
            })
        }
    }

    /**
     * Called when the page is first loaded
     */
    useEffect(() => {
        const abortController = new AbortController()
        findQuery(abortController)

        // Cleanup function
        return () => {
            setCurrentOrgUuid(null)
            abortController.abort()
        }
    }, [orgUuid])

    /**
     * The group values as a react select array
     */
    const groupValues = uuidValuePairsToSelectOptions(orgData?.groups ?? null, "uuid", "value", (uuid) => {
        const canRemove = isTechnicianOrAbove && groupList.some((v) => v.value === uuid)
        return !canRemove
    })

    const groupStyles = {
        multiValue: (base, state) => {
            return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed ? { ...base, color: "white", paddingRight: 6 } : base
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: "none" } : base
        },
    }

    return (
        <div className='page-wrapper'>
            <MetaData title={`${isNewItem ? "New" : "Edit"} Organisation`} />
            {isNewGroupOpen && <NewGroupPopup closePopup={doClosePopup} orgUuid={orgUuid} />}

            <form onSubmit={doSubmit} className='page-form'>
                <Header2 title='Edit Organisation' />
                <RequiredFieldHint />
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className='flex-row flex-column-small flex-gap-32'>
                            <div className='flex-column flex-gap-16 flex-100'>
                                <h3>Key Information</h3>
                                <div className='label-input-wrapper'>
                                    <label htmlFor='code'>Code</label>
                                    <input
                                        id='code'
                                        name='code'
                                        className='rs-input'
                                        type='text'
                                        required={isTechnicianOrAbove}
                                        autoFocus={true}
                                        maxLength={inputMaxLength.organisationCode}
                                        defaultValue={orgData?.code ?? ""}
                                        onChange={isTechnicianOrAbove ? (e) => onInputChange(e) : undefined}
                                        readOnly={!isTechnicianOrAbove}
                                    />
                                    <div className='text-hint'>The code must be unique thoughout the system</div>
                                </div>
                                <div className='flex-row width-100'>
                                    <div className='label-input-wrapper'>
                                        <label htmlFor='name'>Name</label>
                                        <input
                                            id='name'
                                            name='name'
                                            className='rs-input'
                                            type='text'
                                            required
                                            maxLength={inputMaxLength.organisationName}
                                            defaultValue={orgData?.name ?? ""}
                                            onChange={onInputChange}
                                        />
                                    </div>
                                    <div className='label-input-wrapper'>
                                        <label htmlFor='nicknames'>Nicknames</label>
                                        <input
                                            id='nicknames'
                                            name='nicknames'
                                            className='rs-input'
                                            type='text'
                                            maxLength={inputMaxLength.organisationNickname}
                                            defaultValue={orgData?.nicknames ?? ""}
                                            onChange={onInputChange}
                                        />
                                        <div className='text-hint'>Alternative names used to aid searches</div>
                                    </div>
                                </div>
                                <div className='label-input-wrapper flex-100'>
                                    <label htmlFor='description'>Description</label>
                                    <textarea
                                        id='description'
                                        name='description'
                                        type='text'
                                        className='rs-input height-100'
                                        defaultValue={orgData?.description ?? ""}
                                        rows='5'
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className='flex-column flex-gap-16 flex-100'>
                                <h3>Details</h3>
                                <div className='label-input-wrapper'>
                                    <label htmlFor='address'>Address</label>
                                    <textarea
                                        id='address'
                                        name='address'
                                        type='text'
                                        required
                                        className='rs-input'
                                        defaultValue={orgData?.address ?? ""}
                                        rows='5'
                                        onChange={onInputChange}
                                    />
                                </div>
                                <div className='label-input-wrapper'>
                                    <label htmlFor='defaultContact'>Default Contact</label>
                                    <input
                                        type='text'
                                        id='defaultContact'
                                        name='defaultContact'
                                        className='rs-input'
                                        defaultValue={orgData?.defaultContact ?? ""}
                                        maxLength={inputMaxLength.organisationContact}
                                        onChange={onInputChange}
                                    />
                                </div>
                                <div className='flex-row width-100'>
                                    <div className='label-input-wrapper'>
                                        <label htmlFor='phone1'>Phone 1</label>
                                        <input
                                            id='phone1'
                                            name='phone1'
                                            className='rs-input'
                                            type='tel'
                                            required
                                            defaultValue={orgData?.phone1 ?? ""}
                                            maxLength={inputMaxLength.organisationPhone1}
                                            onChange={onInputChange}
                                        />
                                    </div>
                                    <div className='label-input-wrapper'>
                                        <label htmlFor='phone2'>Phone 2</label>
                                        <input
                                            id='phone2'
                                            name='phone2'
                                            className='rs-input'
                                            type='tel'
                                            defaultValue={orgData?.phone2 ?? ""}
                                            maxLength={inputMaxLength.organisationPhone2}
                                            onChange={onInputChange}
                                        />
                                    </div>
                                </div>

                                <div className='label-input-wrapper'>
                                    <label htmlFor='email'>Email</label>
                                    <input
                                        id='email'
                                        name='email'
                                        className='rs-input'
                                        type='email'
                                        defaultValue={orgData?.email ?? ""}
                                        maxLength={inputMaxLength.organisationEmail}
                                        onChange={onInputChange}
                                    />
                                </div>

                                {isTechnicianOrAbove ? (
                                    <div className='label-input-wrapper'>
                                        <Switch
                                            id='isDisabled'
                                            checkedLabel='Organisation Enabled'
                                            uncheckedLabel='Organisation Disabled'
                                            checked={!orgData?.isDisabled ?? false}
                                            onChange={(e) => {
                                                // inverted
                                                const isDisabled = e.target.checked ? false : true
                                                setOrgData((currentValue) => {
                                                    return { ...currentValue, isDisabled }
                                                })
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        {isTechnicianOrAbove ? (
                            <>
                                <Divider padding={16} />
                                <div className='flex-column flex-gap-16'>
                                    <Header3
                                        title='Organisation Groups'
                                        subtitle='Any user assigned to these groups will be able to see this organisation'
                                        subtitleClassName='text-hint'
                                        showDivider={false}
                                    />

                                    <div className='label-input-wrapper'>
                                        <div className='flex-row-center-vertical'>
                                            <Select
                                                className='width-100 react-select-container'
                                                options={groupList}
                                                value={groupValues}
                                                styles={groupStyles}
                                                theme={selectTheme}
                                                onChange={onSelectChange}
                                                isMulti
                                                inputId='groups'
                                                name='groups'
                                                filterOption={selectFilter()}
                                                required
                                                isClearable={true}
                                            />
                                            {isNewItem || !isAdmin ? null : (
                                                <AddCircleOutlineIcon className='faded-icon' onClick={() => setIsNewGroupOpen(true)} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {isTechnicianOrAbove ? (
                            <>
                                <Divider padding={16} />
                                <div className='flex-column flex-gap-16'>
                                    <Header3
                                        title='Technician Notes'
                                        subtitle='Technician notes are only viewable / editable by technicians &amp; admins'
                                        subtitleClassName='text-hint'
                                        showDivider={false}
                                    />

                                    <div className='label-input-wrapper'>
                                        <MarkdownTextArea
                                            id='notes'
                                            className='rs-input'
                                            defaultValue={orgData?.notes ?? ""}
                                            minRows={5}
                                            onChange={onInputChange}
                                            showActionButtons={false}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <div className='button-input-wrapper'>
                            <button type='submit' className='button-contained ' disabled={isSubmitting || isLoading || !isDataValid()}>
                                {isNewItem ? "Create New " : "Save "} Organisation
                            </button>
                        </div>
                    </>
                )}
            </form>
        </div>
    )
}

export default NewOrganisation
