import React from "react"
import { Link } from "react-router-dom"

import EmailIcon from "@mui/icons-material/Email"
import DraftsIcon from "@mui/icons-material/Drafts"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"

import "../../pages/styling/tickets.css"

import { dateFormat, dateTimeClass, dateTimeFormat } from "../../components/utility/ConvertTime"
import TicketTagBar from "../../components/utility/TicketTagBar"
import Tooltip from "../mui/Tooltip"
import DataTable from "../mui/DataTable"
import ErrorBoundary from "../errors/ErrorBoundary"
import ErrorBlock from "../errors/ErrorBlock"
import useUser from "../../hooks/useUser"
import ProfilePhoto from "../ProfilePhoto"
import FlagIcon from "../FlagIcon"
import { dangerColor } from "../../Theme"

/**
 * Basic column with a link
 */
const basicColumns = (params) => {
    return <Link to={`/ticket/${params.id}`}>{params.value}</Link>
}

/**
 * Formated column with a link and tool tip
 */
const formatedColumnsWithTip = (id, formatedValue, toolTip) => {
    return (
        <Tooltip text={toolTip}>
            <Link to={`/ticket/${id}`}>{formatedValue}</Link>
        </Tooltip>
    )
}

/**
 * Formated column with a link and ProfilePhoto component
 */
const formatedColumnsProfilePhoto = (id, userName, toolTip) => {
    return (
        <Tooltip text={toolTip}>
            <Link to={`/ticket/${id}`} className='profile-cell'>
                <ProfilePhoto userName={userName} size='small' className='hidden-small' />
                {userName}
            </Link>
        </Tooltip>
    )
}

/**
 * Gets the correct date format for the due date
 */
const dueDateClass = (params) => {
    return params.row.closedOn ? "date-closed" : dateTimeClass(params.value)
}

/**
 * Ticket table columns
 */
const columns = [
    {
        field: "id",
        headerName: "#",
        flex: 0.2,
        type: "number",
        renderCell: basicColumns,
    },
    {
        field: "isSubscribed",
        headerName: "Subscribed",
        minWidth: 28,
        maxWidth: 28,
        renderHeader: () => <>&nbsp;</>,
        renderCell: (params) => {
            return params.value ? (
                <NotificationsActiveIcon style={{ width: "16px", height: "16px", color: "var(--text-dark-faded)" }} />
            ) : (
                <NotificationsOffIcon style={{ width: "16px", height: "16px", color: "var(--text-dark-faded-extra)" }} />
            )
        },
    },
    {
        field: "hasSeenLastMessage",
        headerName: "Has Seen Last Message",
        minWidth: 28,
        maxWidth: 28,
        renderHeader: () => <>&nbsp;</>,
        renderCell: (params) => {
            return params.value ? (
                <DraftsIcon style={{ width: "16px", height: "16px", color: "var(--text-dark-faded)" }} />
            ) : (
                <EmailIcon style={{ width: "16px", height: "16px", color: dangerColor }} />
            )
        },
    },
    {
        field: "tags",
        headerName: "Tags",
        minWidth: 28,
        maxWidth: 28,
        renderHeader: () => <>&nbsp;</>,
        renderCell: (params) => {
            return (
                <div className='flex-row flex-gap-none'>
                    {params.value &&
                        params.value.map((t) => {
                            return <TicketTagBar color={t.colour} label={t.label} key={t.uuid} />
                        })}
                </div>
            )
        },
    },
    {
        field: "title",
        headerName: "Title",
        flex: 2,
        minWidth: 100,
        hideable: false,
        renderCell: (params) => {
            return (
                <Tooltip text={params.row.firstMessage} nl2Br={true}>
                    <Link to={`/ticket/${params.id}`}>
                        <FlagIcon flag={params.row.flag} style={{ width: "24px", height: "24px", marginRight: "4px" }} />
                        <span className={params.row.hasSeenLastMessage ? null : "tickets-table-new-message"}>{params.value}</span>
                    </Link>
                </Tooltip>
            )
        },
    },
    {
        field: "category",
        headerName: "Category",
        flex: 0.5,
        minWidth: 50,
        renderCell: basicColumns,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.5,
        minWidth: 50,
        renderCell: basicColumns,
    },
    {
        field: "org",
        headerName: "Organisation",
        flex: 1,
        minWidth: 100,
        renderCell: basicColumns,
    },
    {
        field: "createdBy",
        headerName: "Created By",
        flex: 0.5,
        minWidth: 50,
        renderCell: (params) => formatedColumnsProfilePhoto(params.id, params.value),
    },
    {
        field: "createdAt",
        headerName: "Created On",
        flex: 0.5,
        minWidth: 50,
        renderCell: (params) => formatedColumnsWithTip(params.id, dateFormat(params.value), dateTimeFormat(params.value)),
    },
    {
        field: "dueOn",
        headerName: "Due On",
        flex: 0.5,
        minWidth: 50,
        cellClassName: (params) => dueDateClass(params),
        renderCell: (params) => formatedColumnsWithTip(params.id, dateFormat(params.value, "n/a"), dateTimeFormat(params.value)),
    },
    {
        field: "assignedTo",
        headerName: "Assigned To",
        flex: 0.5,
        minWidth: 50,
        renderCell: (params) =>
            formatedColumnsProfilePhoto(
                params.id,
                params.value ? params.value : <small>NOT ASSIGNED</small>,
                params.value ? dateTimeFormat(params.row.assignedOn) : ""
            ),
    },
    {
        field: "lastMessageBy",
        headerName: "Updated By",
        flex: 0.5,
        minWidth: 50,
        renderCell: (params) => formatedColumnsProfilePhoto(params.id, params.value ? params.value?.value : <small>NOT UPDATED</small>),
    },
    {
        field: "updatedAt",
        headerName: "Updated On",
        flex: 0.5,
        minWidth: 50,
        renderCell: (params) => formatedColumnsWithTip(params.id, dateFormat(params.value), dateTimeFormat(params.value)),
    },
    {
        field: "closedBy",
        headerName: "Closed By",
        flex: 0.5,
        minWidth: 50,
        renderCell: (params) =>
            formatedColumnsProfilePhoto(params.id, params.value ? params.value : <small>-</small>, params.value ? dateTimeFormat(params.row.closedOn) : ""),
    },
    {
        field: "isPrivate",
        headerName: "Private",
        flex: 0.5,
        type: "boolean",
        minWidth: 50,
    },
    {
        field: "priority",
        headerName: "Priority",
        flex: 0.5,
        minWidth: 50,
        cellClassName: (params) => (params.value === "High" ? "tt-high-priority" : params.value === "Urgent" ? "tt-urgent-priority" : "tt-normal-priority"),
        renderCell: basicColumns,
    },
]

/**
 * The tickts table, displays the passed tickets
 */
function TicketTable({ tickets, showCheckboxSelection = true, onRowsSelectionChange = undefined }) {
    const { hasAuthRole_TechnicianOrAbove } = useUser()
    const isTechnician = hasAuthRole_TechnicianOrAbove()

    return tickets && tickets?.length == 0 ? (
        <div className='flex-column-center error-full-notice'>
            <p className='flex-center-item'>No Tickets Found</p>
            <div className='flex-center-item sub-title'>Try a different filter or remove the current filter</div>
        </div>
    ) : (
        <ErrorBoundary fallback={<ErrorBlock error={"Try a different filter or remove the current filter"} />}>
            <DataTable
                tableId={"tickets-table"}
                rows={tickets}
                columns={columns}
                getRowId={(u) => u.id}
                defaultColumnVisibility={{
                    status: false,
                    isPrivate: false,
                    closedBy: false,
                    priority: false,
                    dueOn: !isTechnician,
                }}
                checkboxSelection={showCheckboxSelection}
                hideFooter={false}
                onRowsSelectionChange={onRowsSelectionChange}
            />
        </ErrorBoundary>
    )
}

export default TicketTable
