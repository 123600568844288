import React, { createContext, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { Fab, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"

import useUser from "../../hooks/useUser"

const SpeedDialContext = createContext({})

/**
 * Context for the speed dial
 */
export const SpeedDialProvider = ({ children }) => {
    const { auth } = useUser()
    const [speedDialActions, setSpeedDialActions] = useState([])
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    /**
     * Handels the speed dial button need clicked
     * @param {() => {}} onClick the actions onclick event
     */
    const handleClick = (onClick) => {
        try {
            if (onClick) onClick()
        } finally {
            handleClose()
        }
    }

    /**
     * Clears all speed dial actions
     */
    const clearSpeedDialActions = () => {
        setSpeedDialActions([])
    }

    /**
     * Removes the passed speed dial action
     * @param {string[]|string} uuids
     */
    const removeSpeedDialAction = (uuids) => {
        if (Array.isArray(uuids)) {
            setSpeedDialActions((currentItems) => currentItems.filter((action) => !uuids.includes(action.uuid)))
        } else {
            setSpeedDialActions((currentItems) => currentItems.filter((action) => action.uuid !== uuids))
        }
    }

    /**
     * Creates a new speed dial action an returns the UUID for the action
     * @param {string} title
     * @param {ReactElement} icon
     * @param {()=>{}} onClick
     * @param {boolean} requireLogIn
     * @returns {string}
     */
    const newSpeedDialAction = (title, icon, onClick, requireLogIn = true) => {
        const item = {
            uuid: uuidv4(),
            icon,
            title,
            onClick,
            requireLogIn,
        }

        setSpeedDialActions((currentItems) => [...currentItems, item])
        return item.uuid
    }

    // TODO: shoud we hide this on mobile?
    return (
        <SpeedDialContext.Provider
            value={{
                removeSpeedDialAction,
                newSpeedDialAction,
                clearSpeedDialActions,
            }}
        >
            {children}
            {speedDialActions?.length > 0 ? (
                speedDialActions?.length == 1 ? (
                    <Fab
                        aria-label={speedDialActions[0].title}
                        title={speedDialActions[0].title}
                        className='btn-action-button'
                        onClick={() => handleClick(speedDialActions[0].onClick)}
                    >
                        {speedDialActions[0].icon}
                    </Fab>
                ) : (
                    <SpeedDial
                        ariaLabel='Speed Dial'
                        className='btn-action-button'
                        icon={<SpeedDialIcon />}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}
                    >
                        {speedDialActions.map((action) =>
                            auth?.loggedIn || !action.requireLogIn ? (
                                <SpeedDialAction key={action.uuid} icon={action.icon} tooltipTitle={action.title} onClick={() => handleClick(action.onClick)} />
                            ) : null
                        )}
                    </SpeedDial>
                )
            ) : null}
        </SpeedDialContext.Provider>
    )
}

export default SpeedDialContext
