import React, { useEffect } from "react"

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"

import { useIsVisible } from "../hooks/useIsVisible"
import useSpeedDial from "../hooks/useSpeedDial"

/**
 * floating button that send the page to the top
 */
function GoTopButton({ refScrollUp }) {
    const isVisible = useIsVisible(refScrollUp, true)
    const { newSpeedDialAction, removeSpeedDialAction } = useSpeedDial()

    /**
     * Handel the scroll click
     */
    const handleOnClick = () => {
        refScrollUp?.current?.scrollIntoView({ behavior: "smooth" })
    }

    /**
     * Build up the action buttons
     */
    useEffect(() => {
        if (isVisible) return

        const actions = [newSpeedDialAction("Add Image", <KeyboardArrowUpIcon />, handleOnClick, false)]

        return () => {
            removeSpeedDialAction(actions)
        }
    }, [isVisible])

    return null
}

export default GoTopButton
