import React, { useState } from "react"
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import axios, { axiosErrorMessage } from "../../components/axios"
import MarkdownTextArea from "../../components/mui/MarkdownTextArea"
import useAlertContext, { AlertTypes } from "../../hooks/useAlertContext"
import useUser from "../../hooks/useUser"
import ConfirmDialog from "../../components/mui/ConfirmDialog"
import Switch from "../../components/mui/Switch"
import IconButton from "../../components/mui/IconButton"
import { deleteTicketAttachmentFunc } from "../../components/httpRequests/TicketsRequests"

/**
 * Component to wrap the ticket editings functions
 */
function TicketMessageEditor({ ticketMessage, findQuery }) {
    const { auth, hasAuthRole_TechnicianOrAbove, hasAuthRole_Admin } = useUser()
    const { addSnackbarMessage } = useAlertContext()

    const [editMessage, setEditMessage] = useState(undefined)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const isAdmin = hasAuthRole_Admin() ?? false
    const isTechnician = hasAuthRole_TechnicianOrAbove() ?? false

    /**
     * Sets the uuid for the editing message
     */
    const onSetEditMessage = (uuid, message, isPrivate = undefined) => {
        setEditMessage((currentValue) => {
            return { ...currentValue, uuid, message, isPrivate }
        })
    }

    /**
     * Clears the edit message id
     * @param {Event} e
     * @param {string} reason escapeKeyDown || backdropClick || other
     */
    const clearEditMessage = (e, reason) => {
        if (reason === "escapeKeyDown" || reason === "backdropClick") return
        setEditMessage(undefined)
    }

    /**
     * Delete attachment click
     */
    const onDeleteAtachmentClick = async (ticketMessageUuid, file) => {
        if (!isTechnician || !file.uuid) return
        await deleteTicketAttachmentFunc(ticketMessageUuid, file.uuid)
        findQuery()
    }

    /**
     * Called when the edited message is saved
     */
    const onSaveEditMessageAsync = async () => {
        if (isSubmitting || !editMessage || !isTechnician) return

        setIsSubmitting(true)
        try {
            await axios.patch("/api/ticket/update-message", {
                uuid: editMessage.uuid,
                newMessage: editMessage.message,
                isPrivate: editMessage.isPrivate,
            })
            clearEditMessage()
            findQuery()
        } catch (e) {
            console.error(e)
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const isEditing = ticketMessage.uuid === editMessage?.uuid
    const canEditMessage = (ticketMessage?.userUuid === auth?.uuid && isTechnician) || isAdmin

    return (
        <>
            {isTechnician ? (
                <ConfirmDialog
                    isOpen={editMessage !== undefined}
                    title='Message Edit'
                    onClose={clearEditMessage}
                    onNoClick={clearEditMessage}
                    noLabel='Cancel'
                    yesLabel='Save Message'
                    yesDisabled={isSubmitting}
                    onYesClick={onSaveEditMessageAsync}
                    fullWidth={true}
                >
                    <MarkdownTextArea
                        className='width-100'
                        type='text'
                        defaultValue={editMessage?.message ?? ""}
                        minRows={5}
                        showActionButtons={false}
                        onChange={(e) =>
                            setEditMessage((currentValue) => {
                                return { ...currentValue, message: e.target.value }
                            })
                        }
                    />
                    {isAdmin ? (
                        <div className='text-right'>
                            <Switch
                                id='newIsPrivate'
                                checkedLabel='Private Admin Message'
                                uncheckedLabel='Public Message'
                                checked={editMessage?.isPrivate ?? false}
                                onChange={(e) => {
                                    setEditMessage((currentValue) => {
                                        return { ...currentValue, isPrivate: e.target.checked ? true : false }
                                    })
                                }}
                            />
                        </div>
                    ) : null}

                    {ticketMessage?.files?.length > 0 ? (
                        <div>
                            <h3>Attachments</h3>
                            {ticketMessage?.files.map((f) => {
                                return (
                                    <div key={f.uuid} className='small border-bottom flex-row-center-vertical flex-spacebetween'>
                                        <span>{f.filename}</span>
                                        <IconButton hintText='Delete file' onClick={() => onDeleteAtachmentClick(ticketMessage.uuid, f)}>
                                            <DeleteForeverIcon className='faded-icon' />
                                        </IconButton>
                                    </div>
                                )
                            })}
                        </div>
                    ) : null}
                </ConfirmDialog>
            ) : null}

            {isEditing ? (
                <IconButton hintText='Editing message' size='small'>
                    <DoNotDisturbIcon />
                </IconButton>
            ) : canEditMessage ? (
                <IconButton
                    hintText='Edit message'
                    onClick={() => onSetEditMessage(ticketMessage.uuid, ticketMessage.message, ticketMessage.isPrivate)}
                    size='small'
                >
                    <EditIcon />
                </IconButton>
            ) : null}
        </>
    )
}

export default TicketMessageEditor
